$anim-dur: 5s;
$anim-delay: 0s;

.onboarding-backdrop {
    position: fixed;
    z-index: 9998;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    transition: opacity .5s ease;
    pointer-events: none;

    &.onboarding-backdrop-visible {
        opacity: 1;
        pointer-events: all;
    }
}

.onboarding-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 16px 16px 0 0;
    transform: translateY(150%);
    transition: transform .5s ease-out;
    z-index: 9999;

    .onboarding-popup-nfc {
        position: absolute;
        line-height: 0;
        border-radius: 32px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .ant-btn {
        width: 100%;
        border-radius: 10px !important;
        padding: 16px 10px;
        height: auto;
        color: #FFF !important;
        font-weight: 700;
        font-size: 16px;

        &-ghost {
            color: #333 !important;
        }

        &-ghost,
        &-custom {
            background-color: transparent;
            border: none;
            box-shadow: none;
        }

        &-custom {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 8px;
            }
        }

        & + .ant-btn {
            margin-top: 8px;
        }
    }

    .onboarding-popup-image {
        line-height: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image-container {
            background-color: transparent;
            margin-right: -16px;
        }
    }

    .onboarding-popup-content {
        flex: 1 1 100%;
        padding-right: 15px;
        max-width: calc(100% - 140px);

        &.no-image {
            max-width: 100%;
        }

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 25px;

            color: #1B1B1B;
            white-space: pre-line;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #1B1B1B;
            margin-top: 12px;
            white-space: pre-line;
        }
    }

    .onboarding-popup-logo {
        margin-top: 14px;
        margin-bottom: 20px;
    }

    .onboarding-popup-subheader {
        text-transform: uppercase;
        transform: translateY(-20px);
        display: inline-flex;
        font-size: 12px;
        color: #1B1B1B;
        height: 17px;
        line-height: 17px;
        background-color: #C6EDFF;
        font-weight: 700;
        padding: 0 4px;
        border-radius: 4px;
    }

    .onboarding-popup-buttons {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        img + .ant-btn {
            margin-top: 8px;
        }
    }

    .onboarding-popup-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background-color: #1F2329;
        border-radius: 8px;
        margin: 20px 0;
        width: 100%;

        li {
            width: 48px;
            height: 48px;
            border-radius: 16px;
            overflow: hidden;
            margin: 0 7px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .onboarding-popup-designs-wrap {        
        overflow-x: scroll;
    }

    .onboarding-popup-designs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0;
        white-space: nowrap;
        padding: 0 6px;
        box-sizing: content-box;

        li {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            overflow: hidden;
            margin: 0 16px 0 0;
            background-color: red;
            position: relative;

            &.design-item-current {
                box-shadow: 0px 0px 6px #23b7f9, 0px 0px 6px #23b7f9;
            }

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: 0;
                height: 0;
                border: 20px solid var(--text-color);
                border-bottom-color: var(--custom-color);
                border-right-color: var(--custom-color);
                border-radius: 20px;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.onboarding-popup-visible {
        transform: translateY(0);
    }

    &.onboarding-popup-animate {
        &.onboarding-popup-type-stick_animation {
            .onboarding-popup-content {
                p {
                    animation: show-after-stick ease-in $anim-dur $anim-delay 1;
                }
            }
    
            .onboarding-popup-nfc {
                animation: nfc-stick ease-out $anim-dur $anim-delay 1;
            }
        }
    }

    &.onboarding-popup-type-default {
        overflow: hidden;

        .onboarding-popup-wrap {
            display: flex;
            flex-direction: row;
            align-items: stretch;
        }

        .onboarding-popup-image {
            flex: 0 0 140px;
            margin-right: -16px;
            margin-top: -16px;
            margin-bottom: -16px;
        }
    }

    &.onboarding-popup-type-icons,
    &.onboarding-popup-type-design {
        .onboarding-popup-content {
            max-width: 100%;
            padding-right: 0;

            h3 {
                padding-top: 16px;
            }
        }
    }


    &.onboarding-popup-type-stick_static,
    &.onboarding-popup-type-stick_animation {
        .onboarding-popup-wrap {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &.onboarding-popup-type-stick_static {
        .onboarding-popup-image {
            flex: 0 0 140px;
            margin-top: -48px;
            margin-bottom: -24px;
        }

        .onboarding-popup-nfc {
            right: 54px;
            top: 121px;
            width: 48px;
            height: 48px;
        }
    }

    &.onboarding-popup-type-stick_animation {
        .onboarding-popup-wrap {
            flex-direction: row-reverse;
        }

        .onboarding-popup-content {
            padding-right: 0px;
            padding-left: 30px;
        }

        .onboarding-popup-image {
            margin-top: -16px;
            flex: 0 0 140px;

            .image-container {
                margin-right: 0;
            }
        }

        .onboarding-popup-nfc {
            left: 98px;
            top: 115px;
            transform: translate(-50%, -50%) translateZ(64px) perspective(160px) rotateY(20deg) scale(1);
            transform-origin: center center;
            width: 64px;
            height: 64px;

            &:after {
                content: '';
                background: url(../logo-overlay-t.png) no-repeat;
                background-size: cover;
                position: absolute;
                left: -1px;
                top: -1px;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@keyframes show-after-stick {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }
    
    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes nfc-stick {
    0% {
        transform: translate(25vw, -50%) translateZ(64px) perspective(80px) rotateY(0) scale(1.3);
        transform-origin: center center;
        opacity: 0;
    }

    20% {
        transform: translate(25vw, -50%) translateZ(64px) perspective(80px) rotateY(0) scale(1.3);
        transform-origin: center center;
        opacity: 1;
    }

    40% {
        transform: translate(25vw, -50%) translateZ(64px) perspective(80px) rotateY(30deg) scale(1.5);
        transform-origin: center center;
        opacity: 1;
    }

    50% {
        transform: translate(25vw, -50%) translateZ(64px) perspective(80px) rotateY(30deg) scale(1.5);
        transform-origin: center center;
        opacity: 1;
    }

    70% {
        transform: translate(-50%, -50%) translateZ(64px) perspective(160px) rotateY(25deg) scale(1);
        transform-origin: center center;
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) translateZ(64px) perspective(160px) rotateY(20deg) scale(1);
        transform-origin: center center;
        opacity: 1;
    }
}

@media (prefers-color-scheme: dark) {
    .onboarding-popup {
        background-color: #212126;
        color: #fff;

        .ant-btn {            
            color: rgba(26, 36, 51, 1) !important;
        }

        .onboarding-popup-content {
            h3, p {
                color: #fff;
            }
        }

        .onboarding-popup-logo {
            svg [fill] {
                fill: #fff;
            }
        }

        .onboarding-popup-icons,
        .onboarding-popup-design {
            background-color: #f2f2f2;
        }

        .ant-btn.ant-btn-ghost {
            color: #fff !important;
        }
    }
}